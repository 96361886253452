import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveProCallout from '../../components/JoinGlassHiveProCallout';
import ReviewCard from '../../components/ReviewCard';
import Loader from '../../components/Loader';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout navTheme="light">
            <Seo title="GlassHive Pro License" />
            <div className="pro-license-page">
                <Container className="container-1">
                    <div className="top-section-b"></div>
                    <Row>
                        <Col className="content-column">
                            <h1 className="white-text">
                                Unlimited MSP Marketing Collateral & Sales
                                Automation for $499/mo
                            </h1>
                            <p className="hero-subtext white-text">
                                We'll do all your 2021 marketing for you! No
                                Long Term Contracts! No Strings Attached!
                            </p>
                            <div className="buttons">
                                {/* <a href="https://app.glasshive.com" className="btn pink first">
                  <span className="btn__mask"></span>
                  <div className="btn__text">
                    Watch Video
                    <div className="play-icon"></div>
                  </div>
                </a> */}
                                <a
                                    href="https://app.glasshive.com/Account/ProSignUp"
                                    className="btn blue"
                                >
                                    <span className="btn__mask"></span>
                                    <span className="btn__text">
                                        Sign Up for GlassHive Pro
                                    </span>
                                </a>
                            </div>
                        </Col>
                        <Col className="image-column">
                            <img
                                className="pro-image"
                                src={require('../../images/pro-license/Hero_Graphic.png')}
                                alt="pro"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-2">
                    <div className="bottom-section-c"></div>
                    <Row>
                        <Col className="one-half description">
                            <h2 className="what-it-is">What it is</h2>
                            <p>
                                GlassHive Pro gives you the ability to
                                automatically execute full IT service-based
                                marketing campaigns that include landing pages,
                                videos, whitepapers, eBooks and emails. All of
                                this within a sales and marketing tool build
                                specifically for IT service providers. Get easy
                                access to:
                            </p>
                            <ul>
                                <li>Over 24 months of pre built campaigns</li>
                                <li>20+ ebooks</li>
                                <li>18+ videos</li>
                                <li>Sales follow up emails</li>
                                <li>Automation </li>
                                <li>Integration with Connectwise & Autotask</li>
                                <li>Setup and execution done for you</li>
                                <li>Completely customizable</li>
                                <li>New content created every month</li>
                            </ul>
                            <p>
                                Get everything you need and more to start
                                generating leads today.
                            </p>
                        </Col>
                        <Col className="one-half">
                            <form
                                id="webinar-form"
                                className="webinar-form"
                                method="POST"
                                name="Webinar Form"
                                action="/pro-license/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Demo"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'webinar-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'webinar-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#webinar-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'flex';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="webinar-form"
                                />
                                <div className="form-container-for-btn">
                                    <div className="top">
                                        <h3>
                                            Want to know more about GlassHive
                                            Pro? Request a demo.
                                        </h3>
                                    </div>
                                    <div className="what-do-we-call-you">
                                        <div className="field name-field">
                                            <label htmlFor="firstName">
                                                What’s your name?
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder="Name"
                                                required
                                            ></input>
                                        </div>

                                        <div className="field email-field">
                                            <label htmlFor="email">
                                                What’s your email address?
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                required
                                            ></input>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label
                                            className="second-label"
                                            htmlFor="company"
                                        >
                                            What company are you with?
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            id="company"
                                        ></input>
                                    </div>

                                    <div className="field">
                                        <label
                                            className="second-label"
                                            htmlFor="title"
                                        >
                                            What is your job title?
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder="Title"
                                            id="title"
                                        ></input>
                                    </div>

                                    <div className="field newsletter-field">
                                        <input
                                            type="radio"
                                            name="newsletter"
                                            placeholder="newsletter"
                                            id="newsletter"
                                        ></input>
                                        <label
                                            className="second-label"
                                            htmlFor="newsletter"
                                        >
                                            Keep Me Updated with Emails and
                                            Newsletters
                                        </label>
                                    </div>

                                    <div className="hide-me field">
                                        <input
                                            data-form-type="Consultation"
                                            className="formcat"
                                            hidden
                                        ></input>
                                    </div>
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg" style={{ color: 'red' }}>
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        type="submit"
                                        className="btn first"
                                        id="sbmt-form-btn"
                                    >
                                        <span className="btn__mask"></span>
                                        <span className="btn__text">Send</span>
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <div className="top-section-d"></div>
                    <div className="bottom-section-d"></div>
                    <Row>
                        <Col>
                            <h3 className="title">
                                How GlassHive Pro compares
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-third warm-up">
                            <div className="category warm-up">
                                <div className="header">
                                    <h3>Warm-Up</h3>
                                </div>
                                <div className="features">
                                    <ul>
                                        <li className="check">1 user</li>
                                        <li className="check">CRM</li>
                                        <li className="check">
                                            Email Campaigns
                                        </li>
                                        <li className="check">Reporting</li>
                                        <li className="check">Integrations</li>
                                        <li className="check">
                                            Unlimited Contacts
                                        </li>
                                        <li className="close">
                                            Marketing Content
                                        </li>
                                        <li className="close">
                                            Landing Page Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Marketing Plans
                                        </li>
                                        <li className="close">Journeys</li>
                                        <li className="close">
                                            SMS{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Premium Support
                                        </li>
                                        <li className="close">
                                            Calling{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Proposal Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col className="one-third pro">
                            <div className="category pro">
                                <div className="header">
                                    <h3>Pro</h3>
                                </div>
                                <div className="features">
                                    <div className="price">
                                        <sup className="dollar-sign">$</sup>499
                                        <span className="per-month">/mo</span>
                                    </div>
                                    <ul>
                                        <li className="check">
                                            Unlimited Users
                                        </li>
                                        <li className="check">CRM</li>
                                        <li className="check">
                                            Email Campaigns
                                        </li>
                                        <li className="check">Reporting</li>
                                        <li className="check">Integrations</li>
                                        <li className="check">
                                            Unlimited Contacts
                                        </li>
                                        <li className="check">
                                            Marketing Content
                                        </li>
                                        <li className="check">
                                            Landing Page Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="check">
                                            Marketing Plans
                                        </li>
                                        <li className="check">Journeys</li>
                                        <li className="check">
                                            SMS{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="check">
                                            Premium Support
                                        </li>
                                        <li className="check">
                                            Calling{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="check">
                                            Proposal Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="buttons">
                                    <a
                                        href="https://app.glasshive.com/Account/ProSignUp"
                                        className="btn pink"
                                    >
                                        <span className="btn__mask"></span>
                                        <span className="btn__text">
                                            Sign Up for GlassHive Pro
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col className="one-third contender">
                            <div className="category contender">
                                <div className="header">
                                    <h3>Contender</h3>
                                </div>
                                <div className="features">
                                    <ul>
                                        <li className="check">3 users</li>
                                        <li className="check">CRM</li>
                                        <li className="check">
                                            Email Campaigns
                                        </li>
                                        <li className="check">Reporting</li>
                                        <li className="check">Integrations</li>
                                        <li className="check">
                                            Unlimited Contacts
                                        </li>
                                        <li className="check">
                                            Marketing Content
                                        </li>
                                        <li className="check">
                                            Landing Page Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Marketing Plans
                                        </li>
                                        <li className="close">Journeys</li>
                                        <li className="close">
                                            SMS{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Premium Support
                                        </li>
                                        <li className="close">
                                            Calling{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                        <li className="close">
                                            Proposal Builder{' '}
                                            <span className="asterisk">*</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="coming-soon">
                            <div className="asterisk">*</div> = Coming soon
                        </div>
                    </Row>
                    <div className="top-blob-section-d"></div>
                    <div className="bottom-blob-section-d"></div>
                </Container>
                <Container className="container-4">
                    <div className="bottom-section-d"></div>
                    <Row>
                        <Col>
                            <h2 className="title">
                                See what people have to say:
                            </h2>
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-1 z-index-999">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-1 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="iqynedjfns"
                                thumbnailUrl="https://embedwistia-a.akamaihd.net/deliveries/2faabd87baa50177547b6fde99115b070275364a.jpg?image_crop_resized=960x540&amp;"
                                clientCompany="Blue River Tech Partners"
                                clientName="Joseph Pena"
                                description="It gives you a ton of information that I didn't even know you could get."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="sic2gsvpml"
                                thumbnailUrl="https://embed-fastly.wistia.com/deliveries/b9b47eb2698851495370ea322728131edf82f551.jpg?image_crop_resized=960x540&amp;"
                                clientCompany="Kinetic Technology Group"
                                clientName="Brett Harryman"
                                description="It is the easiest and easiest to look at platform by any means out of any of the other CRMs out there on the market"
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-2">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-2 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="2c75ajjetv"
                                thumbnailUrl="https://embed-fastly.wistia.com/deliveries/8a905d8432112781197bf8ab78d608fb11a7d55c.jpg?image_crop_resized=960x540&amp;"
                                clientCompany="Brainlink"
                                clientName="Raj Goel"
                                description="I love that my marketing information isn't siloed into yet another vendor portal."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="2ly40jnhk6"
                                thumbnailUrl="https://embedwistia-a.akamaihd.net/deliveries/49fde07382b6ca78965970a972f2cf7cc2216b9b.jpg?image_crop_resized=960x540&amp;"
                                clientCompany="IT Business Hero"
                                clientName="Raul Olave"
                                description="It was a breath of fresh air that we had a CRM system that could not only do automated market research, but also integrate with all the platforms that we use on a daily basis."
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5">
                    <div className="top-section-e"></div>
                    <Row>
                        <Col>
                            <JoinGlassHiveProCallout />
                        </Col>
                    </Row>
                    <div className="background-graphic-f"></div>
                    <div className="top-section-f"></div>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
