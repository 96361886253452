import React from "react"
import "./style.scss"

const JoinGlassHiveProCallout = () => {
  return (
		<div className="join-glasshive-pro-callout">
			<div className="content">
				<h3 className="white-text margin-bottom-0">
					Sign up for
				</h3>
				<h3 className="white-text margin-bottom-0">
					GlassHive Pro today! {/* TODO: Is the pro license name going to change with the rebrand? */}
				</h3>
			</div>
			<div className="action">
				<a href="https://app.glasshive.com/Account/ProSignUp" className="btn white">
					Create an Account
				</a>
			</div>
		</div>
	)
}

export default JoinGlassHiveProCallout